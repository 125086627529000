import React from "react";
import { Box, Text } from "@theme-ui/components";

const StatsBox = ({
  label = "&nbsp;",
  unit = "Percentage",
  value = "&nbsp;",
  show = true,
}) => (
  <Box
    sx={{
      bg: "text",
      color: "white",
      position: "absolute",
      bottom: [3, 4],
      right: [3, 4],
      width: 180,
      opacity: show ? 1 : 0,
      transition: "opacity 0.3s ease-in",
      maxHeight: 99999999,
      borderRadius: 10,
      overflow: "hidden",
      "& > *": {
        fontWeight: "100 !important",
      },
    }}
  >
    <Text
      variant="label"
      as="h2"
      sx={{
        p: [2],
        py: [2],
        textTransform: "initial",
        borderBottom: "0.5px solid",
        borderColor: "lightGray",
      }}
      dangerouslySetInnerHTML={{ __html: label }}
    />
    <Text
      as="label"
      variant="label"
      sx={{
        color: "lightGray",
        float: "left",
        p: 2,
        fontSize: "8px !important",
      }}
    >
      {unit}
    </Text>
    <Text
      variant="title"
      as="h3"
      sx={{
        px: 3,
        textAlign: "right",
        color: "white",
      }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  </Box>
);

export default React.memo(StatsBox);
