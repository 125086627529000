import React from "react";
import { graphql } from "gatsby";
import { Grid } from "theme-ui";
import Layout from "../../components/layout";
import ThemeableHeader from "../../components/layout/themeable-header";
import DataStory from "../../components/data-story";
import Seo from "../../components/seo";
import NewsletterSignup from "../../components/page-blocks/newsletter-signup";
import camelCase from "lodash.camelcase";
import ReusableLatestContent from "../../components/page-blocks/reusable-latest-content";

const processCsvJSON = (csvJSON) => {
  const rows = JSON.parse(csvJSON);
  return rows.map(({ g, gc, c, cc, ...rest }) => {
    const filterables = Object.keys(rest).map((key) => {
      return {
        searchKey: camelCase(key),
        label: key,
        value: rest[key],
      };
    });
    const newRow = {
      filterables,
    };
    if (gc) {
      newRow.geographyCode = gc;
      newRow.geography = g;
    }
    if (cc) {
      newRow.countryCode = cc;
      newRow.country = c;
    }
    return newRow;
  });
};

const DataStoriesIndex = ({
  data: {
    category,
    result: { dataStories },
  },
}) => {
  const dataStoryData = dataStories.map((dataStory) => {
    return {
      title: dataStory.title,
      slug: dataStory.slug,
      story: dataStory.fields.story,
      dataLabel: dataStory.fields.dataLabel,
      dataTemplate: dataStory.fields.dataTemplate,
      dataFileUrl: dataStory.fields?.data?.url,
      geoJSON: dataStory.fields?.data?.geoJSON,
      csvJSON: dataStory.fields?.data?.csvJSON,
      sourceLabel: dataStory.fields?.sourceLabel,
      sourceUrl: dataStory.fields?.sourceUrl,
      csvData: processCsvJSON(dataStory.fields?.data?.csvJSON),
      categoryLabel: dataStory.dataCategories.nodes[0].name,
      categorySlug: dataStory.dataCategories.nodes[0].slug,
    };
  });
  const cat = category.nodes[0];
  return (
    <Layout>
      <Seo title="Data Stories" />
      <ThemeableHeader
        title={cat?.name}
        active="/data-stories"
        pageIntroduction={cat.description}
        theme="Data Stories"
      />
      <Grid as="section" columns={1} gap={[5, 6]} sx={{ my: [5, 6] }}>
        {dataStoryData.map((dataStory, index) => (
          <DataStory key={index} {...dataStory} />
        ))}
        <ReusableLatestContent />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query DataCategoryQuery($slug: String!) {
    category: allWpDataCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        name
        description
      }
    }
    result: allWpDataStory(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        dataCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
      }
    ) {
      dataStories: nodes {
        slug
        dataCategories {
          nodes {
            slug
            name
          }
        }
        title
        fields: dataStoryCustomFields {
          dataLabel
          data: dataFile {
            url: mediaItemUrl
            csvJSON
            geoJSON
          }
          story
          dataTemplate
          sourceLabel
          sourceUrl
        }
      }
    }
  }
`;

export default React.memo(DataStoriesIndex);
