import darkGreenColorScale from "./color-scales/dark-green";
import coralColorScale from "./color-scales/coral";

export const getStepColor = (score, color = "darkGreen") => {
  const colorScales = {
    darkgreen: darkGreenColorScale,
    coral: coralColorScale,
  };
  const colorScale = colorScales[color] ?? darkGreenColorScale;
  const floatScore = parseFloat(score);
  const step = colorScale.reduce((previousValue, currentValue) => {
    const cv = parseFloat(currentValue.lowerBound);
    const pv = parseFloat(previousValue.lowerBound);
    if (floatScore === 0) {
      return previousValue;
    }
    if (pv <= floatScore && floatScore <= cv) {
      return previousValue;
    }
    return currentValue;
  });
  return step.hex;
};

export const getMinMaxValues = (input) => {
  const values = input
    .reduce((prev = [], curr) => {
      if (!curr.filterables) {
        return [];
      }
      return curr.filterables.map((filter) => filter.value).concat(prev);
    })
    .map((value) => parseFloat(value))
    .filter(Number);
  const max = Math.max(...values);
  const min = Math.min(...values);
  return {
    max,
    min,
    range: max - min,
  };
};

export const processBarChartData = (
  inputData,
  activeFilter,
  sortAlpha,
  alphaSortKey = "geography",
  color = "darkGreen",
  percentage = false
) => {
  const m = getMinMaxValues(inputData);
  return inputData
    .filter((f) => {
      let n = f.filterables.filter((f) => f.searchKey === activeFilter)[0]
        .value;
      return parseInt(n) > 0;
    })
    .sort((i, v) => {
      const iValue = parseFloat(
        i.filterables.filter((f) => f.searchKey === activeFilter)[0].value
      );
      const vValue = parseFloat(
        v.filterables.filter((f) => f.searchKey === activeFilter)[0].value
      );
      return vValue - iValue;
    })
    .map((item) => {
      const value = item.filterables.filter(
        (f) => f.searchKey === activeFilter
      )[0].value;
      return {
        label: item[alphaSortKey],
        value: `${value}${percentage ? "%" : ""}`,
        barWidth: percentage
          ? `${value}%`
          : parseInt(value) === m.min
          ? "1"
          : ((value - m.min) / m.range) * 100,
        bgColor: getStepColor((value - m.min) / m.range, color),
      };
    })
    .map((item, index) => {
      return {
        ...item,
        label: `${index + 1}. ${item.label}`,
      };
    })
    .sort((i, v) => {
      const clean = (s) => s.split(". ")[1];
      if (sortAlpha) {
        if (clean(i.label) < clean(v.label)) {
          return -1;
        }
        if (clean(i.label) > clean(v.label)) {
          return 1;
        }
        return 0;
      }
      return 1;
    });
};
