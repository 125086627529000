import React, { memo } from "react";
import { Virtuoso } from "react-virtuoso";
import { Box, Grid, Text } from "theme-ui";
import { processBarChartData } from "./utils";

const Row = memo(({ label, value, bgColor = "black", barWidth = false }) => {
  return (
    <Grid
      columns={[8]}
      key={`row-${label}-${value}`}
      sx={{
        pl: [3, 4],
        py: 4,
        borderBottom: "1px solid",
        borderBottomColor: "lighterGray",
        mr: [4],
      }}
    >
      <Text
        sx={{
          fontWeight: "bold",
          gridColumn: "span 3",
          textWrap: "none",
          overflowX: "scroll",
          whiteSpace: "nowrap",
        }}
        as="p"
        variant="paragraph"
      >
        {label}
      </Text>
      <Text
        sx={{
          fontWeight: "bold",
          gridColumn: "span 1",
        }}
        as="p"
        variant="paragraph"
      >
        {value}
      </Text>
      <Box
        sx={{
          gridColumn: "span 4",
          boxSizing: "border-box",
          height: 20,
          bg: bgColor,
          transition: "width 0.75s ease",
          width: barWidth
            ? `${parseInt(barWidth)}%`
            : parseInt(value)
            ? `${value}%`
            : 0,
        }}
      ></Box>
    </Grid>
  );
});

const BarChartList = ({ activeFilter, csvData, sortAlpha, dataTemplate }) => {
  const uk = dataTemplate === "uk";
  const alphaSortKey = uk ? "geography" : "country";
  const color = uk ? "darkGreen" : "coral";
  const percentage = uk;
  const listData = processBarChartData(
    csvData,
    activeFilter,
    sortAlpha,
    alphaSortKey,
    color,
    percentage
  );
  return (
    <Virtuoso
      style={{ minHeight: 420 }}
      data={listData}
      itemContent={(index, data) => {
        return <Row key={`row-${index}`} {...data} />;
      }}
    />
  );
};

export default BarChartList;
