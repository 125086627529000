const colorScale = [
  {
    hex: "#ff5a5f",
    lowerBound: "0.00",
  },
  {
    hex: "#fa5767",
    lowerBound: "0.05",
  },
  {
    hex: "#f45470",
    lowerBound: "0.10",
  },
  {
    hex: "#ef5178",
    lowerBound: "0.15",
  },
  {
    hex: "#e94e80",
    lowerBound: "0.20",
  },
  {
    hex: "#e34b88",
    lowerBound: "0.25",
  },
  {
    hex: "#dd4890",
    lowerBound: "0.30",
  },
  {
    hex: "#d74598",
    lowerBound: "0.35",
  },
  {
    hex: "#d043a0",
    lowerBound: "0.40",
  },
  {
    hex: "#c940a8",
    lowerBound: "0.45",
  },
  {
    hex: "#c13eaf",
    lowerBound: "0.50",
  },
  {
    hex: "#b93cb7",
    lowerBound: "0.55",
  },
  {
    hex: "#b03abf",
    lowerBound: "0.60",
  },
  {
    hex: "#a738c7",
    lowerBound: "0.65",
  },
  {
    hex: "#9d36cf",
    lowerBound: "0.70",
  },
  {
    hex: "#9235d7",
    lowerBound: "0.75",
  },
  {
    hex: "#8534df",
    lowerBound: "0.80",
  },
  {
    hex: "#7633e7",
    lowerBound: "0.85",
  },
  {
    hex: "#6432ef",
    lowerBound: "0.90",
  },
  {
    hex: "#4c32f7",
    lowerBound: "0.95",
  },
];
export default colorScale;
