import React from "react";
import { Text } from "theme-ui";
import AppButton from "../layout/app-button";

const FilterControls = ({ filters, activeFilter, setActiveFilter }) => {
  return (
    <>
      <Text variant="label">Filter By</Text>
      <hr sx={{ my: [3] }} />
      {filters &&
        filters.map((filter) => (
          <AppButton
            colors={{
              default: {
                background:
                  filter.searchKey === activeFilter ? "text" : "lighterGray",
                text: filter.searchKey === activeFilter ? "white" : "text",
                iconColor: "text",
              },
              hover: {
                background:
                  filter.searchKey === activeFilter ? "text" : "#47464E",
                text: "white",
              },
            }}
            icon="filter"
            key={filter.searchKey}
            sx={{
              marginRight: [2, 3],
              marginBottom: [2, 3],
            }}
            onClick={() => setActiveFilter(filter.searchKey)}
          >
            {filter.label}
          </AppButton>
        ))}
      <hr sx={{ my: [3] }} />
    </>
  );
};

export default FilterControls;
