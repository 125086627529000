import { Box, Text } from "@theme-ui/components";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import Legend from "./legend";
import { getMinMaxValues, getStepColor } from "./utils";

const MapFallback = () => {
  return (
    <Box>
      <Text>There was an error in the map data</Text>
    </Box>
  );
};

const InteractiveArea = (props) => {
  const { code, reportCurrentArea, hexColor } = props;
  const geoProps = {
    key: props.geography.rsmKey,
    geography: props.geography,
    fill: hexColor,
    identifier: props.identifier,
    style: props.style,
    stroke: "white",
    strokeWidth: 0.15,
    onMouseEnter: () => {
      reportCurrentArea(code);
    },
  };
  return <Geography {...geoProps} />;
};

const UKMap = ({
  geoJSON,
  csvData,
  dataTemplate,
  activeFilter,
  reportCurrentArea,
}) => {
  const m = getMinMaxValues(csvData);
  return (
    <Box
      sx={{
        minHeight: "100%",
        cursor: "pointer !important",
        overflow: "hidden",
        "& svg": {
          overflow: "visible",
        },
        "& path": {
          // transition: "fill 0.4s ease",
          outline: "none !important",
        },
      }}
    >
      <ErrorBoundary FallbackComponent={MapFallback}>
        <Legend
          values={{
            min: {
              value: m.min,
              color: getStepColor(0.99, "darkGreen"),
            },
            max: {
              value: m.max,
              color: getStepColor(0.01, "darkGreen"),
            },
            mid: {
              value: m.max - m.min / 2,
              color: getStepColor(0.5, "darkGreen"),
            },
          }}
        />
        <ComposableMap
          projection={"geoMercator"}
          projectionConfig={{
            rotate: [4.4, 0],
            center: [2.5, 53.0],
            scale: 3500,
          }}
          style={{
            width: "100%",
            height: "auto",
          }}
        >
          <ZoomableGroup maxZoom={20} minZoom={1} zoom={1}>
            <Geographies geography={geoJSON}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const hasFeatures = geo?.features;
                  if (!hasFeatures) return null;
                  // throw new Error(
                  //   `Item has incorrect data: ${JSON.stringify(geo, null, 2)}`
                  // );
                  const code = geo.features[0].properties.code;
                  const datum = csvData.filter(
                    (datum) => datum.geographyCode === code
                  )[0];
                  const { value } = datum.filterables.filter(
                    (f) => f.searchKey === activeFilter
                  )[0];
                  const hexColor = getStepColor(
                    (value - m.min) / m.range,
                    "darkGreen"
                  );
                  return (
                    <InteractiveArea
                      reportCurrentArea={reportCurrentArea}
                      hexColor={hexColor}
                      key={geo.rsmKey}
                      identifier={geo.rsmKey}
                      geography={geo}
                      code={code}
                      style={{
                        hover: {},
                        default: {
                          cursor: "pointer !important",
                          // outline: "none",
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </ErrorBoundary>
    </Box>
  );
};

export default React.memo(UKMap);
