import React from "react";
import { Box, Grid, Flex, Text } from "@theme-ui/components";

const Legend = ({ values }) => {
  return (
    <Grid
      sx={{ position: "absolute", top: 20, left: 20, width: 30 }}
      columns={2}
      gap={2}
    >
      <Box
        sx={{
          border: "1px solid",
          borderColor: "offWhiteBorder",
          background: `linear-gradient(${values.min.color}, ${values.mid.color}, ${values.max.color})`,
          width: [10, 15],
          height: [50, 80],
          top: 10,
          left: 10,
          borderRadius: 3,
        }}
      />
      <Flex
        sx={{
          fontSize: [1, 2],
          flexDirection: "column",
          justifyContent: "space-between",
          textShadow: `
				 0.05em 0 white,
        0 0.05em white,
        -0.05em 0 white,
        0 -0.05em white,
        -0.05em -0.05em white,
        -0.05em 0.05em white,
        0.05em -0.05em white,
        0.05em 0.05em white;
				`,
        }}
      >
        <Text
          sx={{
            mt: "-2px",
          }}
        >
          High
        </Text>
        <Text sx={{ mb: "-2px" }}>Low</Text>
      </Flex>
    </Grid>
  );
};

export default Legend;
