import { saveAs } from "file-saver";
import React, { useState } from "react";
import { Box, Flex, Grid, Switch, Text } from "theme-ui";
import AppButton from "../layout/app-button";
import BarChartList from "./bar-chart-list";
import FilterControls from "./filter-controls";
import MapController from "./map-controller";
import { getMinMaxValues } from "./utils";

const extractFilters = (exampleRow) => {
  const filters = exampleRow.filterables.map(({ label, searchKey }) => {
    return { label, searchKey };
  });
  return filters;
};

const DataStory = ({
  title,
  subtitle,
  story,
  dataLabel,
  categoryLabel,
  dataFileUrl,
  categorySlug,
  dataTemplate,
  geoJSON,
  csvData,
  sourceLabel = false,
  sourceUrl,
  slug = "not-found",
}) => {
  const filters = extractFilters(csvData[0]);
  const [activeFilter, setActiveFilter] = useState(filters[0].searchKey);
  const [sortAlpha, setSortAlpha] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const m = getMinMaxValues(csvData);
  return (
    <Box
      id={`${slug}`}
      sx={{
        maxWidth: "column",
        mx: "auto",
        px: [3, 4, 5],
      }}
    >
      <Box sx={{}}>
        <Box sx={{ bg: "offWhite", borderRadius: 30, overflow: "hidden" }}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "offWhiteBorder",
              p: [3, 4],
            }}
          >
            <Box>
              <Text variant="label" sx={{ color: "darkGreen" }}>
                {categoryLabel}
              </Text>
            </Box>
            <Grid columns={[1, 2, 3]} gap={[2, 3, 5, 6]}>
              <Text
                variant="title"
                sx={{ gridColumn: "span 2", fontSize: [4, 4, 5, 6] }}
              >
                {title}
              </Text>
              <Grid
                sx={{
                  alignItems: "center",
                  gridColumn: ["span 1", "span 2", "span 1"],
                }}
              >
                {sourceLabel && (
                  <>
                    <Text
                      as="a"
                      target="_blank"
                      href={sourceUrl}
                      variant="paragraph"
                      sx={{ color: "text" }}
                    >
                      Data collected from: {sourceLabel}
                    </Text>
                    <AppButton
                      colors={{
                        default: { background: "darkGreen", text: "white" },
                      }}
                      to={sourceUrl}
                      color="text"
                      icon="arrowRight"
                    >
                      <span sx={{ display: ["none", "inline"] }}>
                        View{` `}
                      </span>
                      Source
                    </AppButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid
            columns={[1]}
            sx={{
              borderBottom: "1px solid",
              borderColor: "offWhiteBorder",
              p: [3, 4],
            }}
          >
            <Text
              as="span"
              variant="paragraph"
              sx={{ maxWidth: 900 }}
              dangerouslySetInnerHTML={{ __html: story }}
            />
            <Box>
              <AppButton
                sx={{}}
                as="a"
                icon="arrowDown"
                colors={{
                  default: {
                    background: "highlight",
                    text: "white",
                  },
                }}
                onClick={() => saveAs(dataFileUrl, `${slug}.csv`)}
              >
                Download CSV
              </AppButton>
            </Box>
          </Grid>
          <Grid columns={[1, 3]} gap={[0, 2]}>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
                p: [3, 4],
              }}
            >
              <Text
                as="h3"
                variant="subtitle"
                sx={{ mt: [0, 0, 0, 0], fontSize: [2, 3, 4] }}
              >
                {dataLabel}
              </Text>
              <Box>
                {filters.length > 1 && (
                  <FilterControls
                    filters={filters}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                )}
                <Box
                  sx={{
                    opacity: showMap ? 0 : 1,
                    my: [3],
                    display: showMap ? ["none", "block"] : "block",
                  }}
                >
                  <Switch
                    checked={sortAlpha}
                    onChange={() => {
                      setSortAlpha(!sortAlpha);
                    }}
                    sx={{
                      "input:checked ~ &": {
                        backgroundColor: "coral",
                      },
                    }}
                    label="Sort A-Z"
                  />
                  <hr />
                </Box>
                <Grid columns={2} sx={{}}>
                  <Box sx={{ mt: [3] }}>
                    <AppButton
                      icon="mapMarker"
                      color="text"
                      onClick={() => setShowMap(true)}
                      sx={{
                        color: showMap ? "white" : "text",
                        "&:hover": {
                          bg: showMap ? "text" : "#47464E",
                        },
                        bg: showMap ? "text" : "lighterGray",
                      }}
                    >
                      Map
                    </AppButton>
                  </Box>
                  <Box sx={{ mt: [3], button: { float: "right" } }}>
                    <AppButton
                      color="text"
                      icon="chart"
                      onClick={() => setShowMap(false)}
                      sx={{
                        color: !showMap ? "white" : "text",
                        "&:hover": {
                          bg: !showMap ? "text" : "#47464E",
                        },
                        bg: !showMap ? "text" : "lighterGray",
                      }}
                    >
                      Chart
                    </AppButton>
                  </Box>
                </Grid>
              </Box>
            </Flex>
            <Box
              sx={{
                gridColumn: "span 2",
                borderLeft: ["0px solid", "1px solid"],
                borderLeftColor: ["offWhiteBorder", "offWhiteBorder"],
                position: "relative",
                maxHeight: 500,
                minHeight: [null, null, 466],
              }}
            >
              {!showMap ? (
                <BarChartList
                  csvData={csvData}
                  activeFilter={activeFilter}
                  sortAlpha={sortAlpha}
                  m={m}
                  dataTemplate={dataTemplate}
                />
              ) : (
                <MapController
                  dataTemplate={dataTemplate}
                  csvData={csvData}
                  geoJSON={geoJSON}
                  activeFilter={activeFilter}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DataStory;
