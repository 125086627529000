const colorScale = [
  {
    hex: "#ff5a5f",
    lowerBound: "0.00",
  },
  {
    hex: "#ff6466",
    lowerBound: "0.03",
  },
  {
    hex: "#fe6f6f",
    lowerBound: "0.07",
  },
  {
    hex: "#fd7675",
    lowerBound: "0.10",
  },
  {
    hex: "#fb7c7c",
    lowerBound: "0.13",
  },
  {
    hex: "#f88384",
    lowerBound: "0.17",
  },
  {
    hex: "#f68889",
    lowerBound: "0.20",
  },
  {
    hex: "#f38c8f",
    lowerBound: "0.23",
  },
  {
    hex: "#ef9095",
    lowerBound: "0.27",
  },
  {
    hex: "#ec929a",
    lowerBound: "0.30",
  },
  {
    hex: "#e8949f",
    lowerBound: "0.33",
  },
  {
    hex: "#e296a4",
    lowerBound: "0.37",
  },
  {
    hex: "#de96a8",
    lowerBound: "0.40",
  },
  {
    hex: "#d996ab",
    lowerBound: "0.43",
  },
  {
    hex: "#d295af",
    lowerBound: "0.47",
  },
  {
    hex: "#cd93b2",
    lowerBound: "0.50",
  },
  {
    hex: "#c791b4",
    lowerBound: "0.53",
  },
  {
    hex: "#bf8eb6",
    lowerBound: "0.57",
  },
  {
    hex: "#b98bb8",
    lowerBound: "0.60",
  },
  {
    hex: "#b387b9",
    lowerBound: "0.63",
  },
  {
    hex: "#aa81ba",
    lowerBound: "0.67",
  },
  {
    hex: "#a37cba",
    lowerBound: "0.70",
  },
  {
    hex: "#9c77ba",
    lowerBound: "0.73",
  },
  {
    hex: "#916fb9",
    lowerBound: "0.77",
  },
  {
    hex: "#8969b8",
    lowerBound: "0.80",
  },
  {
    hex: "#8162b7",
    lowerBound: "0.83",
  },
  {
    hex: "#7658b5",
    lowerBound: "0.87",
  },
  {
    hex: "#6c50b3",
    lowerBound: "0.90",
  },
  {
    hex: "#6348b0",
    lowerBound: "0.93",
  },
  {
    hex: "#553cac",
    lowerBound: "0.97",
  },
];
export default colorScale;
