import { Box } from "@theme-ui/components";
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import Legend from "./legend";
import { getMinMaxValues, getStepColor } from "./utils";

const InteractiveArea = (props) => {
  const { code, reportCurrentArea, hexColor } = props;
  const geoProps = {
    key: props.geography.rsmKey,
    geography: props.geography,
    fill: hexColor,
    identifier: props.identifier,
    style: props.style,
    stroke: "white",
    strokeWidth: 0.15,
    onMouseEnter: reportCurrentArea
      ? () => {
          reportCurrentArea(code);
        }
      : null,
  };
  return <Geography {...geoProps} />;
};

const InternationalMap = ({
  csvData,
  dataTemplate,
  activeFilter,
  reportCurrentArea,
}) => {
  const m = getMinMaxValues(csvData);
  return (
    <Box
      sx={{
        minHeight: "100%",
        cursor: "pointer !important",
        overflow: "hidden",
        "& svg": {
          overflow: "visible",
        },
        "& path": {
          // transition: "fill 0.4s ease",
          outline: "none !important",
        },
      }}
    >
      <Legend
        values={{
          min: {
            value: m.min,
            color: getStepColor(0.99),
          },
          max: {
            value: m.max,
            color: getStepColor(0.01),
          },
          mid: {
            value: m.max - m.min / 2,
            color: getStepColor(0.5),
          },
        }}
      />
      <ComposableMap
        data-tip=""
        width={1000}
        height={1200}
        projection={"geoMercator"}
        projectionConfig={{
          rotate: [4.4, 0],
          center: [2.5, 60.0],
          scale: 100,
        }}
      >
        <ZoomableGroup maxZoom={20} minZoom={2} zoom={3}>
          <Geographies
            geography={
              "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"
            }
          >
            {({ geographies }) =>
              geographies.map((geo) => {
                const code = geo.properties["ISO_A3"];
                let hexColor = "#E0E0E0";
                const datum = csvData.filter(
                  (datum) => datum.countryCode === code
                )[0];
                if (datum) {
                  const { value } = datum.filterables.filter(
                    (f) => f.searchKey === activeFilter
                  )[0];
                  hexColor = getStepColor(
                    (value - m.min) / m.range,
                    "darkGreen"
                  );
                }
                return (
                  <InteractiveArea
                    reportCurrentArea={datum ? reportCurrentArea : null}
                    hexColor={hexColor}
                    key={geo.rsmKey}
                    identifier={geo.rsmKey}
                    geography={geo}
                    code={code}
                    style={
                      datum
                        ? {
                            hover: {},
                            default: {
                              cursor: "pointer !important",
                              // outline: "none",
                            },
                          }
                        : {}
                    }
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
};

export default React.memo(InternationalMap);
