import React, { useState } from "react";
import UKMap from "./uk-map";
import InternationalMap from "./international-map";
import StatsBox from "./stats-box";

const MapController = ({ dataTemplate, csvData, geoJSON, activeFilter }) => {
  const uk = dataTemplate === "uk";
  const [currentArea, setCurrentArea] = useState("");
  const currentAreaLabelKey = dataTemplate === "uk" ? "geography" : "country";
  const currentAreaSearchKey =
    dataTemplate === "uk" ? "geographyCode" : "countryCode";
  let currentAreaData = false;
  currentArea &&
    (currentAreaData = csvData.filter(
      (item) => item[currentAreaSearchKey] === currentArea
    )[0]);
  const maps = {
    uk: UKMap,
    international: InternationalMap,
  };
  const MapComponent = maps[dataTemplate];
  const label = currentAreaData[currentAreaLabelKey];
  return (
    <>
      <MapComponent
        activeFilter={activeFilter}
        csvData={csvData}
        currentArea={currentArea}
        geoJSON={geoJSON}
        reportCurrentArea={setCurrentArea}
        dataTemplate={dataTemplate}
      />
      <StatsBox
        show={currentAreaData}
        label={label}
        unit={uk ? "Percentage" : "PISA Score"}
        value={
          currentAreaData
            ? currentAreaData.filterables.filter(
                (f) => f.searchKey === activeFilter
              )[0].value + (uk ? "%" : "")
            : "&nbsp;"
        }
      />
    </>
  );
};

export default MapController;
